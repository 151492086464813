/*
document.addEventListener('DOMContentLoaded', () => {
  const infoItems = document.querySelectorAll('.pre-deal-section__item');

  if (window.innerWidth < 540) {
    infoItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        item.classList.toggle('active');
      });
    });
  }
});*/
